.full-width-content {
	width: 100%;
}

table.styled thead th {
	background-color: #247f3b;
	color: white;
	font-size: 1rem;
	font-weight: inherit;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
}

table.styled tbody tr th {
	background-color: #555555;
	color: #ffffff;
	font-size: 1rem;
	font-weight: inherit;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
}

table.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

table.styled td {
	font-size: 0.96rem;
	line-height: 1.4rem;
	padding: 0.375rem 0.625rem;
}

table.striped tr:nth-child( even ) {
	background: #dddddd;
}

.brand {
	position: absolute;
	left: 10%;
	top: 0px;
	width: 251px;
	height: 63px;
	margin-top: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	background-image: url('../images/IDALS-Logo.svg');
	background-position: 50% 50%;
	background-size: 251px 63px;
	background-repeat: no-repeat;
}

.navbar {
	position: fixed;
	top: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 85px;
	padding-left: 0%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

.mainnavlink {
	padding-right: 15px;
	padding-left: 15px;
	font-family: Questrial, sans-serif;
	color: #5a5144;
	font-size: 0.91rem;
}

.mainnavlink.myaccount {
	display: none;
}

.slider {
	height: 400px;
	margin-top: 85px;
}

.mask {
	height: 400px;
}

.slide {
	background-position: 50% 45%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slideheadercaption {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	padding-right: 5rem;
	padding-left: 5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.65);
}

.text-block {
	font-family: Questrial, sans-serif;
	color: #fff;
	font-size: 1rem;
	text-align: center;
	letter-spacing: 1px;
	text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.65);
}

/* --- message --- */

#message {
	margin-bottom: 15px;
}

#message {
	padding: 6px 12px 6px 40px;
	border-style: solid;
	border-width: 1px 0 1px 0;
}

#message p {
	margin: 0;
	padding: 0px;
}

#message.success p {
	color: #006600;
}

#message.error p {
	color: #660000;
}

#message ul {
	margin: 5px 0 5px 15px;
	list-style-image: none;
	list-style-type: square;
}

#message.success {
	background: #ccffcc url("/core/public/shared/assets/images/userinterface/success.png") 12px center no-repeat;
	border-color: #006600;
	color: #006600;
}

#message.error {
	background: #ffcccc url("/core/public/shared/assets/images/userinterface/error.png") 12px center no-repeat;
	border-color: #660000;
	color: #660000;
}

.button,
.primary,
.inside-page-container a.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	background-color: #247f3b;
	color: #ffffff;
	border-radius: 3px;
	display: inline-block;
	float: none;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	letter-spacing: 0px;
	padding: 9px 15px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 200ms ease;
	border: 0;
}

.primary:hover,
.inside-page-container a.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover {
	background-color: #5a5144;
	color: #ffffff;
	border: 0;
}

.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	background-color: #5a5144;
	border-radius: 4px;
	color: #ffffff;
	display: inline-block;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	margin: 0.25rem 0.25rem 0.25rem 0rem;
	padding: 0.625rem 1rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 200ms ease;
	width: auto;
}

.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #3c352d;
	color: #ffffff;
}

.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	background-color: transparent;
	border-radius: 4px;
	border: 2px solid #247f3b;
	color: #247f3b;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	margin: 0.25rem 0;
	padding: 0.625rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: all 200ms ease;
}

.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #247f3b;
	border-color: #247f3b;
	color: #fff;
}

.slogan {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 2rem 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.sloganinfo {
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: 'Open Sans', sans-serif;
	color: #5a5144;
	font-size: 28px;
	text-align: left;
}

.sloganinfo.leftaligned {
	font-family: 'Open Sans', sans-serif;
	text-align: center;
}

.threecolumnsection {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.threecolumnsection .button {
	position: static;
	top: 0;
	right: 0;
	display: inline-block;
	margin-top: 20px;
	float: none;
	border-radius: 3px;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #5a5144;
	font-weight: 700;
	letter-spacing: 0;
	text-transform: uppercase;
}

.threecolumnsection .button:hover {
	background-color: #1b642e;
	color: #fff;
}

.onethirdcolumn {
	width: 33.5%;
	height: 300px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.onethirdcolumn.left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 50%;
	background-repeat: repeat, no-repeat;
}

.onethirdcolumn.middle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.onethirdcolumn.right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.heading-2 {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.65);
}

.text-block-2 {
	font-family: Questrial, sans-serif;
	color: #fff;
	text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.65);
}

.locations {
	display: none;
	padding: 2rem 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.text-block-3 {
	margin-bottom: 1rem;
	font-family: Questrial, sans-serif;
	color: #5a5144;
	font-size: 16px;
	text-align: center;
}

.centeredcontent {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	max-width: 1000px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.image {
	width: 450px;
}

.nav-menu {
	position: absolute;
	right: 10%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-signoff-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #bebebe;
}

.signoff-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 1px none hsla(0, 0%, 100%, 0.5);
	background-color: #5a5144;
}

.div-block-12 {
	width: 50%;
}

.grip-logo-image {
	width: 10rem;
	min-width: 150px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.grip-logo-link {
	float: right;
	opacity: 0.5;
	-webkit-transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
}

.grip-logo-link:hover {
	opacity: 1;
}

#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0 !important;
}

.div-block-11 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.footer-signoff-links {
	display: inline-block;
	margin-right: 1rem;
	border-bottom: 2px solid rgba(78, 77, 93, 0.5);
	line-height: 1.25rem;
}

.footer-signoff-links:hover {
	border-bottom-color: transparent;
}

.sign-off-div-wrap {
	width: 100%;
	min-width: 991px;
	padding-right: 10%;
	padding-left: 10%;
}

.footer-signoff-link-wrap {
	display: inline-block;
	margin-top: 0px;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #b2b2b2;
	font-size: 0.9rem;
	text-align: left;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper.footer-socmed-wrapper {
	float: right;
}

.social-media-wrapper.footer-socmed-wrapper.social-2 {
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.social-media-link-block-2 {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.social-media-link-block-2.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	padding: 2px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px none transparent;
	border-radius: 50%;
	background-color: #247f3b;
	background-image: none;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	cursor: pointer;
}

.social-media-link-block-2.footer-socmed-link-block:hover {
	padding: 2px;
	border: 2px none #fff;
	background-color: #5a5144;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.footer-social-icon-image {
	display: block;
	width: 18px;
	margin-right: auto;
	margin-left: auto;
}

.social-media-section {
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: rgba(90, 81, 68, 0.1);
}

.text-block-4 {
	font-family: Questrial, sans-serif;
	color: #5a5144;
	font-size: 16px;
	text-align: center;
}

.howwecanhelp {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0rem 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.twocolumns {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1000px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-15 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 500px;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.text-block-5 {
	margin-bottom: 20px;
	font-family: Questrial, sans-serif;
	color: #5a5144;
	font-size: 16px;
}

.inside-page-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 85px;
	padding-right: 6rem;
	padding-left: 6rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #5a5144;
	background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25), transparent), url('../images/Inside-Page-Banner.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.inside-page-header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1200px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.breadcrumbs-list-2 {
	padding-top: 3rem;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.82rem;
	line-height: 1.2rem;
}

.breadcrumbs-list-item-2 {
	display: inline-block;
}

.breadcrumb-link-2 {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.75rem;
	text-decoration: none;
	text-transform: uppercase;
}

.breadcrumb-link-2:hover {
	color: #fff;
}

.breadcrumb-link-2.w--current {
	color: #c66b0e;
}

.breadcrumb-link-2.current-breadcrumb {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
}

h1 {
	margin-top: 0px;
	margin-bottom: 0rem;
	padding-bottom: 3rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	line-height: 42px;
}

.paragraph {
	margin-bottom: 1rem;
	font-family: Questrial, sans-serif;
	color: #444;
	font-size: 1rem;
	line-height: 1.3rem;
}

.paragraph.list-item {
	margin-bottom: 0rem;
}

.leftnav-holder {
	width: 25%;
	height: auto;
	margin-right: 2rem;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	background-color: transparent;
}

.button-4 {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1.25rem;
	margin-bottom: 1rem;
	padding: 0.5rem 1rem;
	border: 2px solid #3fae2a;
	background-color: #2d7a1e;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-decoration: none;
}

.button-4:hover {
	background-color: #d2e5c6;
	color: #444;
}

.button-4.tertiary {
	margin-top: 0.25rem;
	margin-right: 0rem;
	margin-bottom: 0.25rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	border-style: solid;
	border-width: 2px;
	border-color: #247f3b;
	border-radius: 4px;
	background-color: transparent;
	color: #247f3b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
}

.button-4.tertiary:hover {
	border-color: #247f3b;
	background-color: #247f3b;
	color: #fff;
}

.text-link {
	border-style: none none solid;
	border-width: 0px 0px 2px;
	border-color: transparent transparent #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #175326;
}

.ordered-list {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.left-nav-list-item {
	margin-bottom: 0px;
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.content-container.threequarterwidth {
	width: 75%;
}

.left-nav-list-link-nested {
	display: block;
	padding: 0.375rem 0.75rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #5a5144;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: none;
}

.left-nav-list-link-nested:hover {
	background-color: rgba(90, 81, 68, 0.1);
	color: #3c352d;
}

.left-nav-list-link-nested.w--current {
	background-image: url('../images/icon_arrow2_right_blk_50.svg');
	background-position: 0px 53%;
	background-size: 9px 9px;
	background-repeat: no-repeat;
	color: #474d47;
}

.qt-data-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.5em 0.75rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: default;
}

.qt-data-row:hover {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 41, 115, 0.05)), to(rgba(40, 41, 115, 0.05)));
	background-image: linear-gradient(180deg, rgba(40, 41, 115, 0.05), rgba(40, 41, 115, 0.05));
}

.qt-data-row.title-row {
	display: block;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	background-color: #247f3b;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.heading-7 {
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
}

.heading-10 {
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	text-transform: uppercase;
}

.h3-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	text-decoration: none;
}

.h3-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #175326;
}

.inside-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0rem 6rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: rgba(90, 81, 68, 0.1);
}

.h4-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	text-decoration: none;
}

.h4-header-link:hover {
	border-bottom-width: 2px;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #175326;
}

.leftnavigation {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0px;
	padding-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.image-9 {
	width: 100%;
	margin-bottom: 20px;
}

.qt-accordion-item-content-wrap {
	overflow: hidden;
	width: 100%;
	height: auto;
	min-height: 345px;
	padding-right: 0rem;
	padding-left: 0rem;
	float: left;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	box-shadow: 0 1px 20px -14px rgba(0, 0, 0, 0.2);
	font-size: 0.875rem;
	line-height: 1.375em;
}

.block-quote {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #247f3b;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #444;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
}

.button-3 {
	display: inline-block;
	width: auto;
	margin: 0.25rem 0.25rem 0.25rem 0rem;
	padding: 0.625rem 1rem;
	border-radius: 4px;
	background-color: #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.button-3:hover {
	background-color: #3c352d;
	color: #fff;
}

.heading-8 {
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	font-weight: 700;
}

.left-nav-list-link {
	display: block;
	padding: 0.5rem 0.75rem;
	border-bottom: 1px solid rgba(60, 53, 45, 0.15);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #5a5144;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-list-link:hover {
	background-color: rgba(90, 81, 68, 0.1);
	box-shadow: inset 3px 0 0 0 #247f3b;
	color: #3c352d;
}

.left-nav-list-link.w--current {
	background-color: rgba(0, 0, 0, 0.01);
	box-shadow: inset 3px 0 0 0 #42a644;
	color: #474d47;
}

.h2-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 700;
	text-decoration: none;
}

.h2-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #175326;
}

h2,
h3,
h4,
h5,
h6 {
	color: #247f3b;
}

h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	border-bottom: 2px solid #5a5144;
	color: #247f3b;
	text-decoration: none;
	transition: all 200ms ease;
}

h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	background-color: transparent;
	border-bottom-color: transparent;
	color: #175326;
}

.button-2 {
	display: inline-block;
	margin-top: 0.25rem;
	margin-right: 0.25rem;
	margin-bottom: 0.25rem;
	padding: 0.625rem 1rem;
	border-radius: 4px;
	background-color: #247f3b;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	line-height: 20px;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.03em;
	text-decoration: none;
	text-transform: uppercase;
	text-shadow: none;
}

.button-2:hover {
	background-color: #175326;
	color: #fff;
}

.h6-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	font-size: 12px;
	line-height: 18px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.h6-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #175326;
}

.qt-data-rows-wrap {
	margin-top: 0rem;
	margin-bottom: 0rem;
	border-bottom: 2px none rgba(40, 41, 115, 0.15);
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 0.9375rem;
	line-height: 1.25em;
}

.left-nav-nested-list {
	margin-bottom: 0px;
	padding-top: 0.375rem;
	padding-bottom: 0rem;
	padding-left: 0.75rem;
	border-top: 1px none rgba(0, 0, 0, 0.09);
}

.unordered-list {
	margin-bottom: 1rem;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
}

.qt-data-row-text {
	display: inline-block;
	min-width: 20%;
	font-size: 0.85rem;
	text-align: center;
}

.qt-data-row-text.first-col-date {
	min-width: 45%;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	font-weight: 600;
	text-align: left;
}

.inside-page-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.inside-page-container a {
	border-style: none none solid;
	border-width: 0 0 1px;
	border-color: transparent transparent #5a5144;
	color: #247f3b;
	text-decoration: none;
}

.inside-page-container a:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #175326;
}

.inside-page-container p {
	margin-bottom: 1rem;
	color: #444;
	font-size: 1rem;
	line-height: 1.3rem;
}

.inside-page-container ul {
	margin-bottom: 1rem;
	margin-top: 0;
	padding-left: 40px;
}

.inside-page-container ul li {
	margin-bottom: 0;
	color: #444;
	font-size: 1rem;
	line-height: 1.3rem;
}

.inside-page-container.white-background {
	width: 100%;
	max-width: 1200px;
	padding: 2rem;
	background-color: #fff;
}

.h5-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #247f3b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.h5-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #175326;
}

.right-sidebar-section-wrap {
	margin-bottom: 2.5rem;
	margin-left: 2rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.right-sidebar-section-title {
	margin-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 2px solid #3c352d;
	font-family: 'Open Sans', sans-serif;
	color: #3c352d;
	font-size: 1.125rem;
	line-height: 1.3755rem;
	font-weight: 700;
}

.right-sidebar-list-item {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
	font-family: 'Open Sans', sans-serif;
	font-size: 0.85rem;
	line-height: 1.25rem;
}

.link {
	border-bottom: 1px none transparent;
	-webkit-transition: color 200ms ease, border-color 200ms ease;
	transition: color 200ms ease, border-color 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #175326;
	font-size: 0.85rem;
	line-height: 1rem;
	text-decoration: none;
}

.link:hover {
	border-bottom: 1px none #c31d23;
	color: #3c352d;
}

.right-navigation {
	width: 25%;
}

.mainnavlink.selected,
.w-nav-link.w--current {
	color: #247f3b;
}

@media (max-width: 991px) {
	.mainnavlink {
		color: #fff;
		text-align: center;
	}

	.mainnavlink:hover {
		background-color: hsla(0, 0%, 100%, 0.05);
	}

	.mainnavlink.myaccount {
		display: block;
	}

	.slider {
		height: 400px;
	}

	.mask {
		height: 400px;
	}

	.button.myaccountheader {
		display: none;
	}

	.sloganinfo.leftaligned {
		text-align: center;
	}

	.onethirdcolumn.left {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.onethirdcolumn.middle {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.onethirdcolumn.right {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.text-block-2 {
		text-align: center;
	}

	.locations {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.centeredcontent {
		width: 80%;
	}

	.menu-button {
		position: absolute;
		right: 10px;
		font-size: 40px;
	}

	.menu-button.w--open {
		background-color: #fff;
		color: #5a5144;
	}

	.icon {
		color: #247f3b;
	}

	.nav-menu {
		width: 100%;
		background-color: #5a5144;
	}

	.signoff-div {
		height: auto;
	}

	.grip-logo-link {
		margin-right: 0rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		float: right;
	}

	.footer-signoff-links {
		margin-right: 0.75rem;
		margin-left: 0rem;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
		padding-right: 20px;
		padding-left: 20px;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-bottom: 1.5rem;
		float: left;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.social-media-wrapper.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.text-block-4 {
		text-align: center;
	}

	.howwecanhelp {
		padding: 2rem;
	}

	.twocolumns {
		width: 100%;
	}

	.div-block-15 {
		height: auto;
		padding-left: 1rem;
	}

	.inside-page-header {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		background-size: auto, auto;
	}

	.inside-page-header-container {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.leftnav-holder {
		display: none;
	}

	.content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.content-container.threequarterwidth {
		width: 100%;
	}

	.inside-page-content {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.leftnavigation {
		width: 100%;
		margin-top: -80px;
		margin-bottom: 0px;
		padding-top: 120px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.qt-accordion-item-content-wrap {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.button-3 {
		margin-bottom: 1rem;
		margin-left: 0rem;
	}

	.qt-data-rows-wrap {
		min-height: auto;
		margin-bottom: 1rem;
	}

	.right-sidebar-section-wrap {
		margin-left: 0rem;
	}

	.right-sidebar-section-title {
		font-size: 1.25rem;
	}

	.right-navigation {
		display: none;
	}
}

@media (max-width: 767px) {
	.slider {
		height: 300px;
	}

	.mask {
		height: 300px;
	}

	.heading {
		font-size: 30px;
		line-height: 35px;
	}

	.slogan {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.threecolumnsection {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.onethirdcolumn.left {
		width: 100%;
	}

	.onethirdcolumn.middle {
		width: 100%;
	}

	.onethirdcolumn.right {
		width: 100%;
	}

	.locations {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.centeredcontent {
		width: 100%;
	}

	.icon-2 {
		margin-left: 0px;
		font-size: 24px;
	}

	.icon-3 {
		margin-right: 0px;
		font-size: 24px;
	}

	.signoff-div {
		height: auto;
	}

	.div-block-12 {
		width: 100%;
	}

	.grip-logo-link {
		margin-bottom: 0rem;
		float: none;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.footer-signoff-link-wrap {
		display: inline-block;
		width: 100%;
		float: none;
	}

	.footer-copyright {
		float: none;
	}

	.twocolumns {
		width: 100%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.div-block-15 {
		width: 100%;
		padding-left: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.text-block-5 {
		text-align: center;
	}

	.breadcrumbs-list-2 {
		line-height: 1.3rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	.qt-data-row.title-row {
		font-size: 0.9375rem;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.inside-page-content {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.qt-accordion-item-content-wrap {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.block-quote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.qt-data-rows-wrap {
		min-height: auto;
		font-size: 0.875rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}
}

@media (max-width: 479px) {
	.brand {
		width: 200px;
		background-size: 200px;
	}

	.navbar {
		padding-left: 5px;
	}

	.slider {
		height: 250px;
	}

	.mask {
		height: 250px;
	}

	.slideheadercaption {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.heading {
		font-size: 24px;
		line-height: 28px;
	}

	.text-block {
		font-size: 0.85rem;
		line-height: 1rem;
	}

	.slogan {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.sloganinfo {
		margin-bottom: 10px;
		font-size: 22px;
		line-height: 26px;
	}

	.icon-2 {
		top: 210px;
	}

	.icon-3 {
		top: 210px;
	}

	.slide-nav {
		display: none;
	}

	.signoff-div {
		height: auto;
	}

	.grip-logo-link {
		float: none;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.footer-copyright {
		margin-bottom: 0.5rem;
		text-align: left;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.social-media-link-block-2.footer-socmed-link-block {
		margin-right: 0.65rem;
		margin-left: 0.65rem;
	}

	.howwecanhelp {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.div-block-15 {
		padding-left: 0rem;
	}

	.inside-page-header {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.breadcrumbs-list-2 {
		line-height: 1.3rem;
	}

	.intro-paragraph {
		line-height: 1.5rem;
	}

	.leftnavigation {
		margin-top: -100px;
		margin-bottom: 0px;
		padding-top: 120px;
		padding-bottom: 0px;
	}

	.inside-page-container.white-background {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}
